import React from "react";
import { Switch } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import Login from "./Views/Login";
import Home from "./Views/Home";
import {
  AuthenticatedRoute,
  UnauthenticatedRoute,
} from "./Components/SecureRoutes";

const Routes = () => {
  return (
    <AnimatePresence>
      <Switch>
        <AuthenticatedRoute exact path="/">
          <Home></Home>
        </AuthenticatedRoute>

        <UnauthenticatedRoute exact path="/login">
          <Login></Login>
        </UnauthenticatedRoute>
      </Switch>
    </AnimatePresence>
  );
};

export default Routes;
