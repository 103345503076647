import React, { useContext } from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { AppContext } from "../Libs/AppContext";

export const AuthenticatedRoute = ({ children, ...rest }) => {
  // const { pathname, search } = useLocation();
  const {
    state: { isAuthenticated },
  } = useContext(AppContext);

  return (
    <Route {...rest}>
      {isAuthenticated ? children : <Redirect to="/login"></Redirect>}
    </Route>
  );
};

export const UnauthenticatedRoute = ({ children, ...rest }) => {
  const {
    state: { isAuthenticated },
  } = useContext(AppContext);

  return (
    <Route {...rest}>
      {!isAuthenticated ? children : <Redirect to="/"></Redirect>}
    </Route>
  );
};
