import React, { useEffect, useContext } from "react";
import { Auth } from "aws-amplify";
import Routes from "./Routes";
import { AppContext } from "./Libs/AppContext";
import * as Constants from "./Libs/Constants";
import { onError } from "./Libs/ErrorLib";
import "./App.css";

function App() {
  const {
    state: { isAuthenticated, isAuthenticating },
    dispatch,
  } = useContext(AppContext);

  useEffect(() => {
    onLoad();
  }, []);

  const onLoad = async () => {
    try {
      await Auth.currentSession();
      dispatch({ type: Constants.USER_HAS_AUTHENTICATED, payload: true });
    } catch (e) {
      if (e !== "No current user") {
        onError(e);
      }
    }
    dispatch({ type: Constants.FINISH_AUTHENTICATING });
  };

  return <div className="App">{!isAuthenticating && <Routes></Routes>}</div>;
}

export default App;
