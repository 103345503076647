import React, { useState, useRef, useEffect, useContext } from "react";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { motion } from "framer-motion";
import styled from "styled-components";
import LoginHeader from "./Components/LoginHeader";
import LoaderButton from "./Components/LoaderButton";
import { AppContext } from "../../Libs/AppContext";
import * as Constants from "../../Libs/Constants";
import { onError } from "../../Libs/ErrorLib";

const Login = () => {
  const emailInput = useRef(null);
  const {
    state: { isLoading },
    dispatch,
  } = useContext(AppContext);
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    emailInput.current.focus();
  }, []);

  const validateForm = () => {
    return email.length > 0 && password.length > 0;
  };

  const handleLogin = async () => {
    dispatch({ type: Constants.SET_IS_LOADING, payload: true });
    try {
      await Auth.signIn(email, password);
      dispatch({ type: Constants.USER_HAS_AUTHENTICATED, payload: true });
      history.push("/");
      dispatch({ type: Constants.SET_IS_LOADING, payload: false });
    } catch (e) {
      onError(e);
      dispatch({ type: Constants.SET_IS_LOADING, payload: false });
    }
  };

  return (
    <motion.div
      style={styles.loginContainerPage}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <LoginHeader>
        <h1
          style={{
            textAlign: "center",
            color: "#26abff",
            textShadow: "1px 1px 2px black",
          }}
        >
          Ryan Callery, M.D.
        </h1>
      </LoginHeader>
      <LoginFormContainer>
        <LoginSpanText>Log-in to your account</LoginSpanText>
        <TextInput
          ref={emailInput}
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        ></TextInput>
        <TextInput
          placeholder="Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        ></TextInput>
        <LoaderButton
          isLoading={isLoading}
          disabled={!validateForm()}
          onClick={() => handleLogin()}
        >
          Log-In
        </LoaderButton>
      </LoginFormContainer>
    </motion.div>
  );
};

const styles = {
  loginContainerPage: {
    width: "100%",
    height: "100%",
    position: "relative",
  },
};

const LoginFormContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  width: 30%;
  height: 30%;
  top: 30%;
  left: 1%;
  @media (max-width: 768px) {
    width: 90%;
    height: 90%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const LoginSpanText = styled.span`
  font-size: 3vh;
  font-style: bold;
  width: 90%;
  height: 10%;
`;

const TextInput = styled.input.attrs((props) => ({
  type: props.type || "text",
  placeholder: props.placeholder || null,
}))`
  width: 90%;
  height: 15%;

  border-radius: 5px;
  box-shadow: 0 8px 6px -6px black;
  font-size: 1.5vh;
`;

export default Login;
