import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { motion } from "framer-motion";
import * as Constants from "../../Libs/Constants";
import { Auth } from "aws-amplify";
import { AppContext } from "../../Libs/AppContext";
import LoginHeader from "../Login/Components/LoginHeader";
import LoaderButton from "../Login/Components/LoaderButton";

const Home = () => {
  const {
    state: { isLoading },
    dispatch,
  } = useContext(AppContext);
  const history = useHistory();

  const logout = async () => {
    dispatch({ type: Constants.SET_IS_LOADING, payload: true });
    try {
      await Auth.signOut();
      dispatch({ type: Constants.USER_HAS_AUTHENTICATED, payload: false });
      history.push("/login");
      dispatch({ type: Constants.SET_IS_LOADING, payload: false });
    } catch (e) {
      dispatch({ type: Constants.SET_IS_LOADING, payload: false });
      alert(e);
    }
  };
  return (
    <motion.div
      style={styles.homePageContainer}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {/* ---------------------- */}
      <LoginHeader justifyContent="flex-end">
        <LoaderButton
          disabled={isLoading}
          width="7vw"
          height="80%"
          onClick={logout}
          style={{ marginRight: "1vw" }}
        >
          Logout
        </LoaderButton>
      </LoginHeader>

      {/* ---------------------- */}

      <iframe
        title="Inline Frame Example"
        style={styles.iframeStyle}
        width="414px"
        height="896px"
        src="https://www.kpaponline.org/"
      ></iframe>
    </motion.div>
  );
};

const styles = {
  homePageContainer: {
    width: "100%",
    height: "100%",
    position: "relative",
  },
  iframeStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate3d(-50%, -50%, 0)",
  },
};

const HomePageContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

export default Home;
