import React from "react";
import styled from "styled-components";

const LoginHeader = (props) => {
  const { children, justifyContent } = props;

  console.log(props);
  return (
    <LoginHeaderContainer justifyContent={justifyContent}>
      {children}
    </LoginHeaderContainer>
  );
};

const LoginHeaderContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => props.justifyContent || "center"};
  align-items: center;
  width: 100%;
  height: 5vh;
  top: 0;
  left: 0;
`;

export default LoginHeader;
