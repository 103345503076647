import React, { createContext, useReducer } from "react";
import * as Constants from "./Constants";

const initialState = {
  isAuthenticated: false,
  isAuthenticating: true,
  isLoading: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case Constants.USER_HAS_AUTHENTICATED:
      return { ...state, isAuthenticated: action.payload };
    case Constants.FINISH_AUTHENTICATING:
      return { ...state, isAuthenticating: false };
    case Constants.START_AUTHENTICATING:
      return { ...state, isAuthenticating: true };
    case Constants.SET_IS_LOADING:
      return { ...state, isLoading: action.payload };
    default:
      return state;
  }
};

export const AppContext = createContext();

const PersonalWebSiteAppContext = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};
export default PersonalWebSiteAppContext;
