/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:5ac6ec83-4484-46a1-94cd-60a69a0c4782",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_G1bJNGUzB",
    "aws_user_pools_web_client_id": "64q5navvgs81igrht6vgc83me8",
    "oauth": {}
};


export default awsmobile;
